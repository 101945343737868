.loader-spinner {
  display: block;
  margin: auto;
  width: calc(1rem * 3);
  height: calc(1rem * 2.5);
}

.loader-spinner > div {
  background-color: var(--primary-color);
  height: 100%;
  width: calc(1rem / 3);
  margin: 2px;
  display: inline-block;
  border-radius: 15%;
  animation: loader-animation 1.2s infinite ease-in-out;
}

.loader-spinner > div:nth-of-type(1) {
  animation-delay: -1.1s;
}

.loader-spinner > div:nth-of-type(2) {
  animation-delay: -1s;
}

.loader-spinner > div:nth-of-type(3) {
  animation-delay: -0.9s;
}

.loader-spinner > div:nth-of-type(4) {
  animation-delay: -0.8s;
}

@keyframes loader-animation {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}
